


















import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({
  components: {},
  mixins: [],
})
export default class ExportLoading extends Vue {
  @Prop({ type: Number, default: 100 }) readonly ammount!: number;
  @Prop({ type: Number, default: 0 }) readonly progress!: number;

  get percent() {
    return `${((this.progress / this.ammount) * 100).toFixed(2)}%`;
  }
}
